$hoverColor: #D0DCE7;

.zgb__sub-navigation {
	padding-left: 8px;

	li, a {
		display: block;
	}

	a {
		color: $colorLink;
		padding: 2px 10px;

		transition-property: background;
		transition-duration: 0.5s;

		&:hover,
		&:focus {
			background: $hoverColor;
		}
	}

	em {
		font-weight: bold;
		font-style: normal;
	}

	> ul {
		border: 1px solid $colorBorder;
		box-shadow: 0 2px 5px 0 $colorBorder;
		margin: 0;
		margin-left: -8px;
		padding: 0;

		> li > a {
			border-top: 1px solid $colorBorder;
			font-weight: bold;
		}

		> .zgb__navigation-item-current > a {
			background-color: $colorPrimaryE;
		}

		> li:first-child > a {
			border-top: 0;
		}
	}

	.zgb__navigation-item-active.zgb__navigation-item-parent + li > a {
		border-top: 0;
	}

	ul ul {
		background: #f2f4f6;
		border-top: 1px solid $colorPrimaryC;
		border-bottom: 1px solid $colorPrimaryC;
		margin: 1px 0 0 0;
		padding: 1px 0;

		a {
			border-top: 1px solid $colorBorder;
			padding: 2px 10px 2px 20px;

			&:hover,
			&:focus {
				background: $hoverColor;
			}
		}

		.zgb__navigation-item-active > a {
			background: white;
		}

		li:first-child a {
			border-top: 0;
		}
	}

	ul ul ul {
		background-color: #fff;
		border-top: 1px solid #C4C4C4;
		border-bottom: 1px solid #C4C4C4;

		a {
			padding-left: 30px;
			border: 0;

		}
	}
}
