$line-height: 1.4 !default;
$font-color: #222 !default;
$font-family: sans-serif !default;
$font-size: 16px !default;
$selected-font-color: #fff !default;
$selected-background-color: #b3d4fc !default;

@mixin h5bp-main {
	@include h5bp-base-styles;
	@include h5bp-chromeframe;
}

//
// Base styles: opinionated defaults
//
@mixin h5bp-base-styles {
	/* ==========================================================================
	   Base styles: opinionated defaults
	   ========================================================================== */

	html,
	button,
	input,
	select,
	textarea {
		color: #222;
	}

	body {
		font-size: 1em;
		line-height: 1.4;
	}

	/*
	 * Remove text-shadow in selection highlight: h5bp.com/i
	 * These selection rule sets have to be separate.
	 * Customize the background color to match your design.
	 */

	::-moz-selection {
		background: #b3d4fc;
		text-shadow: none;
	}

	::selection {
		background: #b3d4fc;
		text-shadow: none;
	}

	/*
	 * A better looking default horizontal rule
	 */

	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid #ccc;
		margin: 1em 0;
		padding: 0;
	}

	/*
	 * Remove the gap between images and the bottom of their containers: h5bp.com/i/440
	 */

	img {
		vertical-align: middle;
	}

	/*
	 * Remove default fieldset styles.
	 */

	fieldset {
		border: 0;
		margin: 0;
		padding: 0;
	}

	/*
	 * Allow only vertical resizing of textareas.
	 */

	textarea {
		resize: vertical;
	}
}

//
// Chrome Frame Prompt
//

@mixin h5bp-chromeframe {
	.chromeframe {
		margin: 0.2em 0;
		background: #ccc;
		color: #000;
		padding: 0.2em 0;
	}
}
