/* Wide Content */
$wideContentSubNavigationWidth: 230px;

.zgb__main--wide {
	width: 100% !important;
	padding-right: 0;

	.zgb__sub-navigation {
		width: $wideContentSubNavigationWidth;
	}

	.zgb__main__column {
		float: none;
		width: auto !important;
		padding-left: 0;
		margin-left: $wideContentSubNavigationWidth + 10px;
	}
}

.js .zgb__main--wide {
	position: relative;

	.zgb__sub-navigation {
		float: none;
		position: absolute;
		top: 0;
		left: 0;
		background: white;
		padding-right: 0;
		cursor: pointer;

		margin: 0;
		padding: 0;

		> ul {
			margin: 0;
			padding: 0 16px 20px 0;
			box-shadow: 4px 4px 10px -4px rgba(0, 0, 0, 0.5);
			border: 0;

			> .last {
				border-bottom: 1px solid $colorBorder;
			}
		}

		h2 {
			margin: 0;
			padding: 17px 0 6px 8px;
			border-bottom: 1px solid $colorBorder;

			&:after {
				position: relative;
				content: url(../img/down-arrow-light.png);
				top: -3px;
				margin-left: 10px;
			}
		}
	}

	.zgb__breadcrumb-navigation {
		margin: 0;
		padding: 20px 0 6px ($wideContentSubNavigationWidth + 8);
		border-bottom: 1px solid $colorBorder;
	}

	.zgb__main__column {
		margin-left: 0;
	}
}
