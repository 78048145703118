.js .zgb__aside {
	display: none;
}

.zgb__aside {
	padding-top: 15px;

	h2.csc-firstHeader {
		margin: 3px 0 6px 0;
	}

	.zgb_infocollection a {
		border-bottom: 0;
	}

	.nn__content-effect--accordion__wrapper {
		background: rgba(255, 255, 255, 0.5);
		margin: 0 0 20px -5px;
		border: 1px solid $colorBorder;
		border-top: 0;
		box-shadow: 0 2px 5px 0 $colorBorder;

		li, a {
			display: block;
		}

		h1, h2, h3 {
			font-size: 100%;
			color: $colorText;
			margin: 0;
			padding: 0;
		}

		.ui-accordion-header {
			border-top: 1px solid $colorBorder;
			font-weight: bold;
			cursor: pointer;
			font-size: 100%;
			color: $colorLink!important;
			padding: 2px 10px;
			transition-property: background;
			transition-duration: 0.5s;

			h1, h2, h3 {
				color: $colorLink;
			}
			&:hover {
				background: $colorPrimaryE;
			}
		}

		.ui-accordion-content {
			border-top: 1px solid $colorBorder;
			padding: 2px 10px;
		}

		ul {
			margin: 0 -10px;
			padding: 2px 0;

			li {
				padding: 2px 10px;
				color: $colorLink;

				&:hover,
				&:focus {
					background: $colorPrimaryE;
				}
			}

			a {
				color: $colorLink;
				border: 0 !important;
				padding: 2px 14px 2px 10px;
				margin: -2px -10px;
				background-position: 200px 200px;

				&:hover,
				&:focus {
					background-color: $colorLinkHover;
					background-position: center right;
				}
			}

			li.first > a {
				border-top: 0;
			}
		}
	}

	.zgb__layout-1 .zgb__aside-navigation a {
		transition-property: none;
	}
}

.zgb__aside {
	.zgb__ctype--image,
	.zgb__ctype--text,
	.zgb__ctype--textpic {
		padding-bottom: 2em;
	}
}

.zgb__aside__box,
.zgb__aside .zgb__content--header,
.zgb__aside .zgb__content--text,
.zgb__aside .zgb__content--textpic,
.zgb__aside .zgb__content--image,
.zgb__aside .zgb__plugin--tt_address_pi1 {
	/* margin: 0 0 20px -5px; */

	header {
		padding: 5px 10px 5px 10px;
		border-bottom: 1px solid $colorBorder;

		h2, h3 {
			font-weight: bold;
			font-size: 14px;
			text-transform: none;
			margin: 0;
		}
	}

	ul {
		list-style: none;
		padding: 0 0 0 20px;
		margin: 10px 0;
	}

	.zgb__aside__box__text,
	.csc-textpic-text {
		padding: 2px 10px 7px 10px;
	}

	img {
		width: 100%;
		height: auto;
	}
}

.zgb__aside__box--banner {
	background: white;
	transition-property: background;
	transition-duration: 0.5s;

	&:hover,
	a:focus {
		background: $colorLinkHover;
	}

	header {
		border: 0;
	}
}

#fahrplanform input:focus,
#fahrplanform #buttonSubmit:focus {
	box-shadow: 0 0 3px $colorText;
	outline: 0 none;
}

#fahrplanform input {
	letter-spacing: 0.1ex;
	padding: 2px;
	border: 1px solid #c4c4c4;
}

#itdTimeHour, #itdTimeMinute {
	width: 2em;
}

#itdDateDayMonthYear {
	width: 7em;
}

#itdTripDateTimeDepArr {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: url("/fileadmin/templates/zgb-2013/img/accordion-arrow.png") no-repeat scroll left center rgba(0, 0, 0, 0) !important;
	border: 0 none;
	border-radius: 0;
	cursor: pointer;
	line-height: 1;
	outline: 0 none;
	padding: 2px 12px;
	text-indent: 0.01px;
	text-overflow: "";
}

#fahrplanform select::-ms-expand {
	display: none;
}

#itdTripDateTimeDepArr:focus {
	border: 0;
}

#buttonSubmit {
	margin: 0;
}

#place_origin,
#name_origin,
#place_destination,
#name_destination {
	width: 100%;
	margin: 0 0 0.4em;
}

#fahrplanform {
	padding-top: 1em;
	padding-bottom: 1em;
	font-size: 12px;
}

#itdTripDateTimeDepArr {
	margin: 0.5em 0 0 0;
}

#buttonSubmit {
	margin: 1em 0 0 0;
	padding: 2px 10px;
}

select#itdTripDateTimeDepArr {
	height: 22px;
}

select#itdTripDateTimeDepArr option {
	font-size: 18px;
}
