.csc-uploads {
	li {
		margin: 10px 0;
	}

	.csc-uploads-fileName {
		display: block;
		font-weight: bold;
	}

	.csc-uploads-fileSize {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		color: $colorPrimaryC;
	}
}

/* Mit Icon */
.csc-uploads-1 {
	img {
		float: left;
		margin: 2px 0;
	}

	span {
		margin-left: 30px;
	}
}

/* Mit Preview */
.csc-uploads-2,
.csc-uploads-3 {
	img {
		border: 1px solid $colorBorder;
		float: left;
	}

	span {
		margin-left: 160px;
	}
}
