.zgb__main-nonav {
	width: 76%;
}

.zgb__main--2cols {
	.col1, .col2 {
		width: 50%;
		float: left;
	}

	.col2 {
		padding-left: 25px;
	}

}
