@charset "UTF-8";

@import "vars";
@import "h5bp/normalize";
@import "h5bp/main";

@include h5bp-normalize;
@include h5bp-base-styles;

*,
*:before,
*:after {
	box-sizing: border-box;
}

a {
	color: $colorLink;
	text-decoration: none;
}

/*
body {
	background: white url(../img/schwung-small.jpg) no-repeat;
	overflow-y: scroll;

	//@include transition-property(padding);
	//@include transition-duration(0.6s);
}

.zgb__layout-1 {
	background: white url(../img/schwung-big.jpg) no-repeat;
}
*/
.zgb__wrapper {
	@extend .clearfix;
	position: relative;
	width: auto;
	min-width: 920px;
	max-width: 1220px;
	padding: 0 20px;
	margin: 0 auto;
	background: transparent url(../img/wrapper.png);
	background: rgba(255, 255, 255, 0.85);
}

@media screen and (min-width: 960px) {
	body {
		padding: 0 10px
	}

	.zgb__wrapper {
		box-shadow: 0 10px 30px #555;
	}
}

@media screen and (min-width: 980px) {
	body {
		padding: 0 20px
	}
}

/* Banner */
.zgb__banner {
	padding-top: 10px;
	background: transparent url(../img/logo-shadow.png) no-repeat top left;
}

/* Logo */
.zgb__logo {
	margin: 0 0 10px 35px;
}

.zgb__logo--klima {
	margin: 0 0 10px 37px;
	height: 110px;
}

.zgb__logo--rad {
	margin: 0 0 10px 37px;
	height: 110px;
}

a.zgb__logolink {
	position: relative;
	display: inline-block;
}

a.zgb__logolink:after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

/* Entdecken Sie die Projektregion */
.zgb__projektregion-teaser {
	position: absolute;
	top: 48px;
	right: 23px;
	padding: 33px 56px 0 0;
	line-height: 1.2;
	font-size: 14px;
	text-align: right;
	color: #56738f;

	img {
		position: absolute;
		top: 0;
		right: 0;
		transition: all 0.2s linear;
		transform: scale(0.9);
	}

	&:hover,
	&:focus {
		img {
			transform: scale(1);
		}
	}
}

/* Hauptspalten */
.zgb__main {
	float: left;
	width: 76%;
	margin-bottom: 20px;
	padding-right: 15px;
}

.zgb__aside {
	float: right;
	width: 24%;
	margin-bottom: 20px;
	padding-left: 15px;
}

.zgb__main,
.zgb__aside {
	//transition-property: width;
	//transition-duration: 0.6s;
}

@media screen and (min-width: 1000px) {
	.zgb__main {
		width: 78%;
	}

	.zgb__aside {
		width: 22%;
	}
}

/* Weitere Spalten */
.zgb__sub-navigation {
	float: left;
	width: 33%;
	padding-right: 10px;
}

.zgb__main__column {
	float: right;
	width: 67%;
	padding-left: 10px;
}

@media screen and (min-width: 1000px) {
	.zgb__sub-navigation {
		width: 30%;
	}

	.zgb__main__column {
		width: 70%;
	}
}

/* Footer */
.zgb__footer {
	@extend .clearfix;
	clear: both;
	border-top: 1px solid $colorBorder;
	color: $colorPrimaryC;
	font-size: 14px;
	margin: 170px 0 0 0;
	padding: 10px 20px 200px 20px;

	a {
		color: $colorPrimaryC;
	}

	header {
		@extend .visuallyhidden;
	}

	p, ul, li {
		margin: 0;
		padding: 0;
		display: inline;
	}

	li {
		margin-left: 15px;
	}
}

.zgb__footer__left {
	width: 47%;
	float: left;
	text-align: left;
}

.zgb__footer__right {
	width: 47%;
	float: right;
	text-align: right;
}

@import "main-navigation";
@import "sub-navigation";
@import "service-navigation";
@import "breadcrumb-navigation";
@import "search";
@import "tiles";
@import "news";
@import "sub-banner";
@import "aside";
@import "content";
@import "content-tables";
@import "content-downloads";
@import "content-effects";
@import "content-wide";
@import "content-wide-2cols";
@import "forms";
@import "address";
@import "fancybox";
//@import "jquery-ui-1.10.4.custom";
@import "print";

@import "royalslider";
@import "stage";

@import "h5bp/helpers";
@include h5bp-helpers;
