.zgb__sub-banner {
	height: 120px;
	background-size: cover;
	background-position: top center;
	-ms-behavior: url(/projekte/zgb/js/backgroundsize.min.htc);

	h2 {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		font-size: 25px;
		font-weight: bold;
		font-style: italic;
		text-transform: none;
		background: rgba(255, 255, 255, 0.7);
		background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0) 100%); /* FF3.6+ */
		background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.7)), color-stop(80%, rgba(255, 255, 255, 0.7)), color-stop(100%, rgba(255, 255, 255, 0))); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0) 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(left, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0) 100%); /* Opera 11.10+ */
		background: -ms-linear-gradient(left, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0) 100%); /* IE10+ */
		background: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 80%, rgba(255, 255, 255, 0) 100%); /* W3C */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#44ffffff', GradientType=1); /* IE6-9 */
		color: $colorLink;
		margin: 0;
		padding: 5px 130px 5px 20px;
	}
}

zgb__projekinitiator {
	color: $colorLink;
}
