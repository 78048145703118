/* Searchbox */
.zgb__search {
	width: 220px;
	padding: 5px 0;

	input,
	button {
		background: $colorPrimary;
		color: white;
		border: 1px solid $colorPrimaryA;
		font-size: 14px;
		margin: 0;
		padding: 1px 0;
		vertical-align: top;
		text-align: center;
		border-radius: 2px;

		&:focus {
			outline-color: $colorPrimaryA;
		}
	}

	label span {
		@extend .visuallyhidden;
	}

	::-webkit-input-placeholder {
		color: white;
	}
	:-moz-placeholder {
		color: white;
	}
	::-moz-placeholder {
		color: white;
	}
	:-ms-input-placeholder {
		color: white;
	}

	input {
		width: 65%;
		font-style: italic;
		padding: 1px 10px;

		-moz-appearance: textfield;
		-webkit-appearance: textfield;
		appearance: textfield;
	}

	button {
		width: 17%;
		text-indent: -999px;
		overflow: hidden;
		background: url(../img/search.png) no-repeat center center;
	}
}

@media (min-width: 1000px) {
	.zgb__search {
		width: 21%;
	}
}

/* Search Results */
.tx-indexedsearch-whatis {
	font-weight: bold;
}

.tx-indexedsearch-browsebox,
.tx-indexedsearch-res {
	margin-bottom: 30px;
}

.tx-indexedsearch-res {
	h4 {
		border-bottom: 0;
	}
}
