$colorPrimary: #6DA5BF; /* aus Logo */
$colorPrimary: #4b6f80; /* aus Logo H -20%*/

$colorPrimaryA: #6392a8; /* $colorPrimary H +10%*/
$colorPrimaryB: #5f8da3; /* $colorPrimary H -10%*/
$colorPrimaryC: #c4c4c4; /* $colorPrimary H +20%*/
$colorPrimaryD: #6DA5BF; /* /* $colorPrimary H +40% stage nav-item hover */
$colorPrimaryE: #e5ecf2; /* navigation hover */


$colorSecondary: #d7cc32; /* aus Logo */

$colorText: #545454;
$colorLink: #545454;
$colorInTextLink: #537c8f;
$colorLinkHover: #f4f9fa;

$colorLinkService: #666666;

$colorBorder: #c4c4c4;
