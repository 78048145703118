.ui-helper-hidden {
	display: none;
}

.ui-helper-reset {
	outline: 0;
}

.zgb__main__column {
	.nn__content-effect--tabs__wrapper {
		padding: 10px 0;

		.ui-tabs-nav {
			@extend .clearfix;

			list-style: none;
			padding: 0;
			margin: 0;

			li {
				display: block;
				float: left;
			}

			a {
				display: block;
				padding: 6px 15px;
				border: 1px solid $colorBorder;
				border-width: 1px 1px 0 0;
				background: $colorPrimaryE;

				&:focus {
					outline: 0;
				}
			}

			.ui-tabs-active a {
				padding-bottom: 7px;
				margin-bottom: -1px;
				background: white;
				font-weight: bold;
			}

			li:first-child a {
				border-left-width: 1px;
			}
		}

		.ui-tabs-panel {
			padding: 15px 27px;
			border: 1px solid $colorBorder;
		}
	}

	.nn__content-effect--crop__wrapper {
		display: block;
		@extend .clearfix;

		> .first {
			margin-top: 0;
		}

		> .last {
			margin-bottom: 0;
		}
	}

	.nn__content-effect--crop__link {
		display: block;
		margin-top: 0 !important;
		margin-bottom: 10px !important;
		padding: 5px 0 5px 0;
		text-align: right;
		cursor: pointer;

		a {
			border: 0;
		}
	}

	.nn__content-effect--accordion__wrapper.ui-accordion.ui-widget.ui-helper-reset {
		background-color: #F5F5F5;
		padding: 1em 2em 2em 1em;
	}
;

	.nn__content-effect--accordion__wrapper,
	.nn__content-effect--collapsible__wrapper {
		.ui-accordion-header {
			padding-left: 14px;
			background: url(/fileadmin/templates/zgb-2013/img/accordion-arrow.png) no-repeat left center;
			cursor: pointer;

			h2, h3, h4, h5 {
				margin-bottom: 0;
			}

			h3, h4 {
				margin-top: 0;
				margin-bottom: 0.9em;
			}

		}
		.ui-accordion-header-active {
			background: url(/fileadmin/templates/zgb-2013/img/accordion-arrow-active.png) no-repeat left center;
		}
	}

	.nn__content-effect--accordion,
	.nn__content-effect--collapsible {
		@extend .clearfix;
	}

	.nn__content-effect--accordion__wrapper .ui-widget-content {
		padding-left: 14px;
	}

	.nn__content-effect--accordion table.contenttable {
		border: 1px solid #C4C4C4;
	}

	.nn__content-effect--collapsible > table,
	.nn__content-effect--collapsible > figure {
		margin: 1.5em 0;
	}

	.nn__content-effect--crop__cropped-height {
		overflow: hidden;
		position: relative;

		.nn__content-effect--crop__link a {
			&, &:hover, &:focus {
				background: transparent;
			}
		}

		.nn__content-effect--crop__link--open {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 !important;
			background: url(/fileadmin/templates/zgb-2013/img/cropped-height-link-background_strong.png) repeat-x bottom;
			padding: 30px 0 0 0 !important;
			height: 50px;

			a {
				background: url("/fileadmin/templates/zgb-2013/img/accordion-arrow.png") no-repeat scroll left center rgba(0, 0, 0, 0) !important;
				cursor: pointer;
				padding: 0.2em;
				padding-left: 14px;
				font-weight: bold;
			}
			a:hover,
			a:focus {
				background-color: $colorLinkHover !important;
			}
		}

		.nn__content-effect--crop__link--close {
			a {
				background: url("/fileadmin/templates/zgb-2013/img/accordion-arrow-active.png") no-repeat scroll left center rgba(0, 0, 0, 0);
				padding-left: 14px;
				font-weight: bold;
			}
			a:hover,
			a:focus {
				background-color: $colorLinkHover !important;
			}
		}

	}

	.nn__content-effect--crop {
		.nn__content-effect--crop__link {
			a {
				cursor: pointer;
				padding: 0.2em;
				padding-left: 14px;
				font-weight: bold;
			}

			a:hover,
			a:focus {
				background-color: $colorLinkHover !important;
			}
		}

		.nn__content-effect--crop__link--open {
			a {
				background: url("/fileadmin/templates/zgb-2013/img/accordion-arrow.png") no-repeat scroll left center rgba(0, 0, 0, 0) !important;
			}
		}

		.nn__content-effect--crop__link--close {
			a {
				background: url("/fileadmin/templates/zgb-2013/img/accordion-arrow-active-bottom.png") no-repeat scroll left center rgba(0, 0, 0, 0) !important;
			}
		}

	}

	.zgb__collapsible {
		.nn__content-effect--crop__link {
			border-bottom: 0 solid;
		}
	}

}
