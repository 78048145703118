.tx-ttaddress-pi1 .fn {
	white-space: nowrap;
}

@media screen and (max-width: 1000px) {
	.tx-ttaddress-pi1 .fn {
		white-space: normal;
	}
}

.vcard .fn {
	font-weight: bold;
}

.description, .phone {
	display: block;
}

.tx-ttaddress-pi1 .zgb__aside__box img {
	width: auto;
	max-width: 128px;
	margin: 12px 0 0 12px;
	display: block;
}

.zgb__aside__box .vcard {
	padding-bottom: 0.5em;
	padding-top: 0.5em;
}

.zgb__aside__box .vcard a {
	border-bottom: 0;
}

.zgb__aside__box.zgb__aside__box--banner > header {
	border-bottom: 1px solid #C4C4C4;
}
