.zgb__aside {
	.tx-news-list {
		border-bottom: 1px solid $colorBorder;
		margin: 0 0 10px;
	}
	.zgb__news__item {
		border-top: 1px solid $colorBorder;
		padding: 7px 10px;
		margin-left: -5px;
		background: white;
		transition-property: background;
		transition-duration: 0.5s;

		&:hover,
		a:focus {
			background: $colorLinkHover;
		}

		h3, p {
			font-size: 14px;
			margin: 1px 0;
		}

		.zgb__news__date {
			margin: 0;
			font-style: italic;
		}
	}
}

.zgb__main {
	.zgb__news {
		margin: 20px 0;
	}

	.tx-news-article .tx-news-img-wrap {
		float: right;
		max-width: 60%;
		.mediaelement {
			margin: 0 0 15px 15px;

			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
	.tx-news-topnews .tx-news-img-wrap {
		float: left;
		padding-right: 10px;
		padding-top: 5px;
	}

	.tx-news-list .tx-news-img-wrap {
		float: left;
		max-width: 60%;
		padding-right: 10px;
		padding-top: 5px;
	}

	.zgb__news__item {
		margin: 0 -5px;
		padding: 5px 5px 25px;
		background: white;
		transition-property: background;
		transition-duration: 0.5s;

		&:hover,
		a:focus {
			background: $colorLinkHover;
		}

		h3, p {
			font-size: 14px;
			margin: 1px 0;
		}

		.zgb__news__date {
			float: left;
			font-weight: bold;
			background: url(../img/dot.png) no-repeat right center;
			margin: 0 7px 0 0;
			padding-right: 14px;
		}
	}

	.tx-news-date {
		display: block;
		font-size: 14px;
		font-weight: normal;
	}

	.tx-news-img-caption,
	.tx-news-related-files-size {
		color: $colorPrimaryB;
	}

	.tx-news-img-caption {
		margin-left: 15px;
	}

	.tx-news-teaser-text {
		margin: 10px 0 15px 0;
	}

	.tx-news-related-files-size {
		margin-left: 8px;
	}

	.tx-news-list {
		ul.category-list {
			padding: 0;
			margin: 0;
			clear: both;
			border-bottom: 1px solid #c4c4c4;

			li {
				float: left;
				list-style-type: none;
				padding: 0;
				margin: 0;

				a {
					padding: 5px 6px;
					text-decoration: none;
					border: 0;
					display: inline-block;
				}
				a.active {
					font-weight: bold;
				}
			}
		}

	}

}

/*****************************/
/* styles for news extension */
/*****************************/
.news {
	.article {
		.tx-news-img-wrap {
			margin: 3px 0 5px 12px;
			float: right;
			max-width: 60%;
			padding-top: 5px;
		}
		h3 {
			font-size: 1.25em;
		}
	}
	.page-navigation {
		p {
			float: left;
		}
		ul {
			float: right;
			margin: 0;
			li {
				float: left;
				display: block;
				padding: 0 1em 0 0;
				background: none;
				a {
					display: block;
				}
			}
		}
	}
	.no-news-found {
		/* border-top: 1px solid #c4c4c4; */
		padding: 7px 0;
	}
}

.news-browsebox {
	margin: 15px 0;
	padding-top: 10px;
	border-top: 1px solid #eee;
	ul {
		li {
			margin: 0 5px;
		}
		.prev {
			margin-right: 20px;
			margin-left: 0;
		}
		.next {
			margin-left: 20px;
			margin-right: 0;
		}
		.current-page a {
			font-weight: bold;
		}
	}
}

.tx-news-related-wrap {
	h4 {
		margin-bottom: 0.4em;
	}
	li {
		list-style: none;
	}
}

.zgb__subscribe-rss-link {
	float: right;
	border: 0 none !important;
}

.tx-news .tx-news-related-wrap ul {
	padding-left: 0;
	margin-top: 0.4em;
}
