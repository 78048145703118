.zgb__service-navigation {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 14px;
	line-height: 1;

	header {
		@extend .visuallyhidden;
	}

	em {
		font-style: normal;
	}

	ul {
		margin: 20px 10px;
		padding: 0;
	}

	li {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		border-right: 1px solid $colorPrimaryC;

		&:last-child {
			border-right: 0;
		}
	}

	a {
		display: block;
		color: $colorLinkService;
		text-decoration: none;
		padding: 0 10px;
	}
}

