//
// Helper classes
//

@mixin h5bp-helpers {

	.ir {
		@include image-replacement;
	}

	.hidden {
		@include hidden;
	}

	.visuallyhidden {
		@include visually-hidden;
	}

	.invisible {
		@include invisible;
	}

	.clearfix {
		@include micro-clearfix;
	}

}

// Image replacement
@mixin image-replacement($img: none, $x: 50%, $y: 50%) {
	background-color: transparent;
	border: 0;
	overflow: hidden;
	// IE 6/7 fallback
	*text-indent: -9999px;

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 150%;
	}

	@if $img != none {
		background-image: image-url($img);
		background-position: $x $y;
	}
}

// Uses image dimensions
@mixin sized-image-replacement($img, $x: 50%, $y: 50%) {
	@include image-replacement($img, $x, $y);
	width: image-width($img);
	height: image-height($img);
}

// Hide from both screenreaders and browsers: h5bp.com/u
@mixin hidden {
	display: none !important;
	visibility: hidden;
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v
@mixin visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;

	// Extends the .visuallyhidden class to allow the element to be focusable
	// when navigated to via the keyboard: h5bp.com/p
	&.focusable:active,
	&.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
}

// Hide visually and from screenreaders, but maintain layout
@mixin invisible {
	visibility: hidden;
}

// Clearfix: contain floats
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    `contenteditable` attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that receive the `clearfix` class.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
@mixin micro-clearfix {
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}

	&:after {
		clear: both;
	}

	// For IE 6/7 only
	// Include this rule to trigger hasLayout and contain floats.
	& {
		*zoom: 1;
	}
}
