$barLineHeight: 7px;
$level2Width: 230px;

.zgb__header__bar {
	position: relative;
	background: $colorPrimary;
	border-bottom: $barLineHeight solid $colorSecondary;
	//@include box-shadow(0 3px 6px -2px #555);

	.zgb__search {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.zgb__main-navigation {
	position: relative;
	z-index: 100;
	width: 70%;
	color: white;

	header {
		@extend .visuallyhidden;
	}

	ul {
		display: block;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	a {
		display: block;
		color: inherit;
	}

	em {
		font-style: normal;
	}

	/* level 1 */
	> ul > li {
		position: relative;
		display: inline-block;
		*display: block;
		*zoom: 1;

		padding-right: 4px;
		background: transparent url(../img/main-navigation-dot.png) no-repeat right center;

		&.last {
			padding-right: 0;
			background: none;
		}

		> span,
		> a {
			padding: 4px 8px;
			padding-bottom: 4px + $barLineHeight;
			margin-bottom: -$barLineHeight;
		}

		> span {
			display: block;
			cursor: default;
		}

		&:hover > ul,
		&.zgb--hover > ul {
			display: block
		}

		&.zgb__navigation-item-active > span {
			background: transparent url(../img/main-navigation-arrow.png) no-repeat bottom center;
		}

		&:hover > span,
		&:hover > a,
		&.zgb--hover > span,
		&.zgb--hover > a,
		> span:hover,
		> a:hover,
		> span:focus,
		> a:focus {
			background: transparent url(../img/main-navigation-arrow.png) no-repeat bottom center;
		}
	}

	> ul > .zgb__navigation-item-parent {
		&:hover > span,
		&:hover > a,
		&.zgb--hover > span,
		&.zgb--hover > a,
		> span:hover,
		> a:hover,
		> span:focus,
		> a:focus {
			background: transparent url(../img/main-navigation-arrow-hover.png) no-repeat bottom center;
		}
	}

	/* level 2 */
	> ul > li > ul {
		display: none;
		position: absolute;
		top: 30px + $barLineHeight;
		left: -20px;
		width: $level2Width;
		background: $colorPrimary;
		padding: 4px 0;
		font-size: 14px;

		box-shadow: 0 9px 14px -1px rgba(0, 0, 0, .9);

		> li {
			display: block;

			&.last > a {
				border-bottom: 0;
			}

			> a {
				position: relative;
				z-index: 2;
				padding: 2px 14px;
				border-bottom: 1px solid $colorPrimaryA;
				font-weight: bold;
			}

			&.zgb--intended-hover > a,
			&.zgb__navigation-item-active > a,
			> a:hover,
			> a:focus {
				background-color: $colorPrimaryB;
			}

			&.zgb--intended-hover > div {
				display: block
			}
		}
	}

	/* level 3 */
	> ul > li > ul > li > div {
		display: none;
		position: absolute;
		z-index: 1;
		left: $level2Width;
		width: $level2Width;
		margin-top: -25px;
		background: #F3F5F6;
		border-top: 4px solid #F3F5F6;
		padding: 0;
		font-size: 14px;
		color: #003c66;
		vertical-align: middle;
		overflow: auto;
		overflow-x: hidden;
		text-overflow: ellipsis;
		box-shadow: 0 9px 14px -1px rgba(0, 0, 0, .9);

		ul {
			display: block;
			width: 100%;
			padding: 0;
		}

		li {
			display: block;

			&.last a {
				border-bottom: 0;
			}
		}

		a {
			padding: 2px 16px;
			border-bottom: 1px solid #C4C4C4;
			font-weight: bold;
			line-height: 1.4;
			color: $colorText;
			transition-duration: 0.5s;
			transition-property: background;
		}

		.zgb__navigation-item-active a,
		a:hover,
		a:focus {
			background-color: #D0DCE7;

		}
	}
}

.zgb__main-navigation__start span {
	display: inline-block;
	*display: block;
	*zoom: 1;
	@extend .ir;
	vertical-align: top;
	height: 14px;
	width: 14px;
	margin-top: 3px;
	margin-left: 2px;
	margin-right: 2px;
	background: transparent url(../img/main-navigation-home.png) no-repeat center center;
}
