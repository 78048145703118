.zgb__breadcrumb-navigation {
	margin: 20px 0 17px 0;

	header {
		@extend .visuallyhidden;
	}

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		display: inline-block;
		*zoom: 1;
		*display: inline;
	}

	em {
		color: $colorText;
		font-style: normal;
	}

	a {
		color: $colorLink;
		font-size: 12px;
		padding-left: 12px;
		margin-left: 7px;
		background: url(../img/breadcrumb-navigation-arrow.png) no-repeat left center;
	}

	.first a {
		background: none;
		padding-left: 0;
		margin-left: 0;
	}
}
