/* Tabelle */
.contenttable {
	border: 0;
	border-spacing: 0;
	border-collapse: collapse;
	width: 100%;
	background-color: white;
	border-bottom: 1px solid $colorBorder;
	margin: 15px 0 30px 0;

	caption {
		background-color: #E5ECF2;
		border-top: 1px solid #C4C4C4;
		padding: 0.4em 6px 0.4em 6px;
		font-weight: bold;
		text-align: left;
		visibility: hidden;
		display: none;
	}

	thead th {
		font-weight: normal;
		vertical-align: top;
		padding: 2px 6px 3px 6px;
		border: 0;
		text-align: left;
		background-color: #E5ECF2;
	}

	tbody th {
		font-weight: bold;
		vertical-align: middle;
	}

	td {
		vertical-align: top;
		padding: 9px 6px;
		border: 0;
		border-top: 1px solid $colorBorder;
	}

	tr:nth-child(odd),
	tr.odd,
	.tr-odd {
		background-color: white;
	}

	tr:nth-child(even),
	tr.even,
	tr-even {
		background-color: #F2F4F6;
	}
}

/* Dunkeler Head */
.contenttable-1 {
	caption {
		background-color: $colorPrimary;
		color: white;
		border-top: 1px solid #C4C4C4;
		padding: 0.4em 6px 0.4em 6px;
		font-weight: bold;
		text-align: left;
		visibility: hidden;
		display: none;
	}

	thead th {
		font-weight: normal;
		vertical-align: top;
		padding: 3px 6px 3px 6px;
		border: 0;
		text-align: left;
		background-color: $colorPrimary;
		color: white;
	}
}

.zgb__steps {
	border-spacing: 0;
	border-collapse: separate;
	border: 1px solid #c3c8cc !important;

	td {
		border: 0;
	}

	thead {
		td, th {
			padding: 7px;
		}

		tr {
			background-color: #e2e7eb;
			color: #376587;
			text-align: left;
		}
	}

	tbody {
		td {
			border-top: 1px solid #c3c8cc;
			padding: 10px;
		}

		tr > td:first-child {
			border-left: 5px solid white
		}

		tr.zgb__steps__step--one > td:first-child {
			border-left: 5px solid #a1d49b
		}

		tr.zgb__steps__step--two > td:first-child {
			border-left: 5px solid #009a3c
		}

		tr.zgb__steps__step--three > td:first-child {
			border-left: 5px solid #e87f00
		}
	}
}

.zgb__steps__legend {
	font-size: 0.9em;
	padding: 4px 0 10px 0;

	ul {
		display: inline;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		display: inline;
		margin-right: 10px;
	}

	i {
		border: 1px solid #ccc;
		width: 12px;
		height: 12px;
		display: inline-block;
		*display: block;
		*zoom: 1;
		vertical-align: top;
		margin-top: 4px;
		margin-right: 4px;
	}
}

.zgb__oepnv {
	border: 0;
	border-spacing: 0;
	border-collapse: collapse;
	width: 100%;
	background-color: white;
	border-bottom: 1px solid $colorBorder;
	margin: 15px 0 30px 0;

	caption {
		background-color: #E5ECF2;
		border-top: 1px solid #C4C4C4;
		padding: 0.4em 6px 0.4em 6px;
		font-weight: bold;
		text-align: left;
		visibility: hidden;
		display: none;
	}

	thead th {
		font-weight: normal;
		vertical-align: top;
		padding: 2px 6px 3px 6px;
		border: 0;
		text-align: left;
		background-color: #E5ECF2;
	}

	tbody th {
		font-weight: bold;
		vertical-align: middle;
	}

	td {
		vertical-align: top;
		padding: 9px 6px;
		border: 0;
		border-top: 1px solid $colorBorder;
	}

	tr:nth-child(odd),
	tr.odd,
	.tr-odd {
		background-color: white;
	}

	tr:nth-child(even),
	tr.even,
	tr-even {
		background-color: #F2F4F6;
	}

	tbody {
		tr > td span.zgb__oepnv {
			background: url(../img/oepnv_sprites.png) no-repeat top left;
			height: 24px;
			display: inline-block;
			margin: 0;
			padding: 0;
		}

		tr > td {
			span.sprite--ALT {
				background-position: 0 -74px;
				width: 25px;
				height: 25px;
			}
			span.sprite--AST {
				background-position: 0 -149px;
				width: 25px;
				height: 25px;
			}
			span.sprite--BUS {
				background-position: 0 -224px;
				width: 25px;
				height: 25px;
			}
			span.sprite--MBus {
				background-position: 0 -299px;
				width: 50px;
				height: 25px;
			}
			span.sprite--LBus {
				background-position: 0 -374px;
				width: 25px;
				height: 25px;
			}
			span.sprite--RegioBus {
				background-position: 0 -449px;
				width: 25px;
				height: 25px;
			}
			span.sprite--Tram {
				background-position: 0 -524px;
				width: 25px;
				height: 25px;
			}
		}
		tr > td:first-child {
			padding: 9px 6px 2px;
			text-align: center;
			white-space: nowrap;
		}
	}
}
