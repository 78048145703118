$tileHeight: 177px;
$tileImageWidth: 100px;
$tileMenuWidth: 205px;

.zgb__tiles {
	position: relative;
	@extend .clearfix;
	margin: 20px 0 20px -10px;

	h2 {
		margin-left: 12px !important;
	}

	menu {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		padding: 0;
	}
}

.zgb__ctype--zgb_project {
	float: left;
	width: 50%;
	padding-left: 10px;
	padding-bottom: 10px;
	position: relative;

	.frontEndEditIconLinks {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -8px 0 0 42px !important;
		background: white;
	}
}

@media screen and (min-width: 1160px) {
	.zgb__ctype--zgb_project {
		width: 33.33%;
	}
}

.zgb__tiles--small .zgb__ctype--zgb_project {
	width: 100%;
}

@media screen and (min-width: 1160px) {
	.zgb__tiles--small .zgb__ctype--zgb_project {
		width: 50%;
	}
}

.zgb__tiles__tile {
	position: relative;
	border: 1px solid $colorBorder;
	height: $tileHeight;
	overflow: hidden;

	background: white;
	transition-property: background;
	transition-duration: 0.5s;

	&:hover,
	a:focus {
		background: $colorLinkHover;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: $tileImageWidth;
		height: $tileHeight;
	}

	h3 {
		margin: 7px 0 3px 0;
		font-size: 18px;
		line-height: 1.2;
	}

	p {
		margin: 0;
	}
}

.zgb__tiles__tile__text {
	padding: 6px 3px;
	margin-left: $tileImageWidth + 3px;
}

.zgb__tiles--small .zgb__tiles__tile__text {
	padding: 8px 6px;
	margin-left: $tileImageWidth + 4px;
}

.zgb__tiles__tile__tag {
	display: block;
	font-style: italic;
}

.zgb__tiles__options {
	z-index: 10;
	cursor: pointer;
	text-align: right;
}

.zgb__tiles__options ul {
	display: none;
	list-style: none;
	width: $tileMenuWidth;
	padding: 3px 0;
}

.zgb__tiles__options__label {
	display: block;
	margin-top: 5px;
	padding-right: 20px;
	background: transparent url(../img/down-arrow-light.png) no-repeat right center;
}

.zgb__tiles__options:hover ul {
	display: block;
	background: white;
	border: 1px solid $colorBorder;
	padding: 2px 0;
	margin: 0;
	box-shadow: 0 2px 6px $colorBorder;
}

.zgb__tiles__options button {
	display: block;
	text-align: left;
	width: 100%;
	background: none;
	border: 0;
	padding: 6px 14px;
	color: $colorLink;
}

.zgb__tiles__options button:hover {
	background: $colorLinkHover;
	color: white;
}
