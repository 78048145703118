$stageHeight: 270px;

.zgb__layout-1 .zgb__aside > :first-child {
	min-height: $stageHeight;
}

.zgb__stage {
	display: none;
}

.js .zgb__stage {
	display: block;
	border: 1px solid #c4c4c4;
	margin: 20px 0;
	width: 100%;
	height: $stageHeight;

	> .rsContent {
		display: none;
	}

	/* Background */
	&,
	.rsOverflow,
	.rsSlide,
	.rsVideoFrameHolder,
	.rsThumbs {
		background: white;
		color: $colorText;
	}

	/***************
	*
	*  Tabs
	*
	****************/

	.rsTabs {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: auto;
		background: white;
		background: rgba(255, 255, 255, 0.8);
	}

	.rsTab {
		&, i {
			display: inline-block;
			*display: inline;
			*zoom: 1;
			cursor: pointer;
		}

		i {
			font-style: normal;
			padding: 4px 10px;
		}

		&:before {
			position: relative;
			top: -2px;
			margin: 0 4px;
			vertical-align: top;
			content: url(../img/dot.png);
		}

		&:first-child {
			margin-left: 8px;

			&:before {
				display: none;
			}
		}
	}

	.rsTab:active i {
		background-color: #f4f4f4;
	}

	.rsTab.rsNavSelected {
		position: relative;
		font-weight: bold;

		&:after {
			display: block;
			content: url(../img/stage-arrow.png);
			position: absolute;
			top: -14px;
			left: 50%;
			margin-left: -8px;
		}
	}

	/***************
	*
	*  Preloader
	*
	****************/

	.rsPreloader {
		width: 20px;
		height: 20px;
		background-image: url(../img/preloader-white.gif);

		left: 50%;
		top: 50%;
		margin-left: -10px;
		margin-top: -10px;
	}

	.rsABlock {
		width: 300px;
    height: auto;//$stageHeight - 70px;
		margin: 20px 40px;
		background-color: white;
		background-color: rgba(255, 255, 255, 0.8);
		font-size: 17px;
		font-weight: bold;
		padding: 15px;
		line-height: 1.3;

		b {
			font-size: 21px;
			line-height: 1.1;
			margin-bottom: 5px;
			display: block;
		}
	}
}

.zgb__stage__slide--right-text .rsABlock {
	left: auto;
	right: 0;
}

.zgb__stage__slide--bottom-right-text .rsABlock {
	left: auto;
	top: auto;
	right: 0;
	bottom: 30px;
	height: auto !important;
	max-height: $stageHeight - 50px;
}
