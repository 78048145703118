.csc-mailform,
.tx-felogin-pi1,
.tx-indexedsearch-searchbox {
	@extend .clearfix;

	form {
		margin: 10px 0;
	}

	label {
		float: none;
		width: 100%;
		display: block;
	}

	input, select, textarea, button {
		border: 1px solid $colorBorder;
		padding: 4px 6px;
		width: 70%;
		color: $colorText;

		&:focus {
			outline: 0;
			box-shadow: 0 0 3px $colorLink;
		}
	}

	option {
		padding: 0;
	}

	input[type=radio],
	input[type=checkbox] {
		float: left;
		width: auto;
		margin-left: 5%;
	}

	button,
	input[type=submit],
	input[type=button] {
		background: #eee;
		border: 1px solid $colorBorder;
		color: black;
		width: auto;
		padding: 5px 11px;
		border-radius: 8px;

		&:hover,
		&:focus {
			background: $colorBorder;
			color: black;
		}
	}

	legend {
		font-weight: bold;
		padding: 0.5em 0;
	}

	ol {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		@extend .clearfix;
		margin: 2px 0 15px 0;
	}

	li ol {
		padding: 13px 0 4px 15px;
	}

	fieldset > ol {
		border: 1px solid $colorBorder;
		border-width: 1px 0 0;
	}

	fieldset.auswahl {

		input, label {
			float: none;
			width: auto;
			padding-left: 1em
		}

		label {
			display: inline;
		}
		.csc-form-element-textblock {
			padding-left: 61px;
		}
		li {
			margin: 15px 0 0px;
		}
		li.csc-form-element-textblock {
			margin: 0;
		}
	}
	fieldset.adresse {
		input, label, select, textarea, button {
			float: none;
		}
		label {
			display: block;
			width: auto;

		}
		b, strong {
			display: block;
		}

	}
}

.zgb__aside {
	.csc-mailform, .tx-felogin-pi1, .tx-indexedsearch-searchbox {
		input, select, textarea, button {
			width: auto;
			float: none;
		}
	}
}
