.zgb__main,
.zgb__aside {
	color: $colorText;
	font-size: 14px;

	h2 {
		font-size: 16px;
		font-weight: normal;
		text-transform: uppercase;
		margin: 17px 0 6px 0;
	}

	h3 {
		font-size: 18px;
		font-weight: bold;
		margin: 16px 0 5px 0;
	}

	h4 {
		font-weight: bold;
		font-size: 14px;
		margin: 11px 0 4px 0;
	}

	.csc-header-n1 {
		margin-top: 0;
	}

	p {
		margin: 5px 1px;
	}

	p.bodytext {
		margin-bottom: 1.1em;
	}

	blockquote {
		margin: 10px 1px;
		padding: 3px 0 3px 20px;
	}

	.indent {
		padding: 0 0 0 30px;
	}

	.zgb__highlight {
		background-color: #f8f862;
	}

	/*
	.csc-frame-frame1 {
	  background: $colorPrimaryE;
	  padding: 10px 7px;
	  margin: 6px -2px 8px -2px;
	}
	*/

	/* File Links */
	ul.csc-uploads {
		padding: 0;

		li {
			@extend .clearfix;

			list-style: none outside none;
			margin: 20px 0;
		}
		img {
			float: left;
			padding-right: 0;
			margin-right: 8px;
			vertical-align: top;
		}
		span {
			display: block;
		}
		span.csc-uploads-fileName {
			text-decoration: none;
		}
	}

	.csc-sitemap ul {
		list-style: disc;
		margin: 7px 0 10px 30px;
		padding: 0;

		li {
			margin: 3px 0;
		}
	}
}

.zgb__iconed-link {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	padding-right: 13px;
	background-image: url(../img/link-internal.png);
	background-repeat: no-repeat;
	background-position: center right;
}

.zgb__anti-spam-img {
	display: inline !important;
	margin: 0 !important;
	width: auto !important;
}

.csc-default {
	a {
		text-decoration: none;
		border-bottom: 1px dotted $colorText;

		&:hover,
		&:focus {
			background-color: $colorLinkHover;
		}

		// .mail
		&.internal-link,
		&.download,
		&.external-link-new-window,
		&.pdf,
		&.external-pdf,
		&.map-link-new-window,
		&.flash-link-new-window {
			@extend .zgb__iconed-link;
		}

		&.download {
			background-image: url(../img/link-download.png);
		}

		&.flash-link-new-window,
		&.map-link-new-window,
		&.external-link-new-window {
			background-image: url(../img/link-external.png);
		}

		&.external-pdf,
		&.pdf {
			background-image: url(../img/link-pdf.png);
			padding-right: 20px;
			margin-right: 5px;
		}
	}

	ul {
		padding: 0 0 0 15px;
	}
	ol {
		padding: 0 0 0 30px;
	}

}

.csc-uploads-fileName a,
.tx-news-related-files-link a {
	@extend .zgb__iconed-link;
	background-image: url(../img/link-download.png);
}

.csc-default .csc-textpic {
	&.zgb__imagecols--1,
	&.zgb__imagecols--2,
	&.zgb__imagecols--3,
	&.zgb__imagecols--4,
	&.zgb__imagecols--5,
	&.zgb__imagecols--6,
	&.zgb__imagecols--7,
	&.zgb__imagecols--8,
	&.zgb__imagecols--9 {
		.csc-textpic-imagecolumn {
			width: 100%;
			padding-left: 10px;
			margin-right: 0;
			overflow: hidden;

			img {
				max-width: 100%;
				width: auto;
				height: auto;
			}
		}

		.csc-textpic-caption,
		.csc-textpic-image {
			display: block;
		}
	}
	&.zgb__imagecols--2 .csc-textpic-imagecolumn {
		width: 50%
	}
	&.zgb__imagecols--3 .csc-textpic-imagecolumn {
		width: 33.333%
	}
	&.zgb__imagecols--4 .csc-textpic-imagecolumn {
		width: 25%
	}
	&.zgb__imagecols--5 .csc-textpic-imagecolumn {
		width: 20%
	}
	&.zgb__imagecols--6 .csc-textpic-imagecolumn {
		width: 16.666%
	}
	&.zgb__imagecols--7 .csc-textpic-imagecolumn {
		width: 14.285%
	}
	&.zgb__imagecols--8 .csc-textpic-imagecolumn {
		width: 12.5%
	}
	&.zgb__imagecols--9 .csc-textpic-imagecolumn {
		width: 11.111%
	}
}

.csc-textpic-above .csc-textpic-imagewrap {
	width: 100%;
}

.csc-textpic-intext-left .csc-textpic-imagewrap,
.csc-textpic-intext-right .csc-textpic-imagewrap {
	margin: 10px 0;
}

div.csc-textpic div.csc-textpic-imagewrap .csc-textpic-imagerow {
	padding: 0.7em 0 0 0;
}

.frontEndEditIconLinks {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	width: 16px !important;
	height: 16px !important;
	border: 0 !important;
	margin: 0 4px !important;
}
