@media print {
	* {
		background: transparent !important;
		color: black !important; /* Black prints faster: h5bp.com/s */
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group; /* h5bp.com/t */
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	@page {
		margin: 0.5cm;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	.zgb__header__bar,
	.zgb__sub-navigation,
	.zgb_infocollection,
	.zgb__service-navigation,
	.zgb__footer,
	.nn__content-effect--crop__link {
		display: none;
	}

	.nn__content-effect--crop__wrapper {
		display: block !important;
	}

	.zgb__breadcrumb-navigation li:before {
		content: "/";
		margin: 0 10px;
	}

	.zgb__breadcrumb-navigation li a {
		padding: 0 !important;
		margin: 0 !important;
	}

	.zgb__logo {
		margin-left: 10px;
	}

	.zgb__sub-banner {
		height: auto !important;
		background: transparent !important;

		h2 {
			background: transparent !important;
			padding: 0 8px !important;;
			font-style: normal !important;;
		}
	}

	.zgb__main__column {
		float: none !important;
		width: auto !important;
	}
}
